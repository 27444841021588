import {
	maskImage,
	maskImageOut,
	stickerSlide,
	stickerSlideOut,
	textSlide,
	textSlideOut
} from './animate'
import { globalIntersectionMargin, supportsWebAnimations } from './global'

/**
 * Init content animations for sections and their content elements
 * @param {HTMLElement} elem
 */
export class SectionAnimation {
	constructor(section) {
		this.s = {
			section: '.js_grid-animate',
			backgroundContent: '.js_background',
			backgroundInner: '.js_background__inner',
			backgroundMedia: '.js_background .media',
			stickerContent: '.js_sticker',
			stickerImage: '.js_sticker picture',
			textContent: '.js_copy',
			textAnimationElements: '.js_copy .js_copy__animation-target'
		}
		this.e = {
			section: section,
			background: section.querySelector(this.s.backgroundContent),
			backgroundMedias: section.querySelectorAll(this.s.backgroundMedia),
			backgroundInner: section.querySelector(this.s.backgroundInner),
			sticker: section.querySelector(this.s.stickerContent),
			stickerImage: section.querySelector(this.s.stickerImage),
			text: section.querySelector(this.s.textContent),
			textAnimationElements: section.querySelectorAll(this.s.textAnimationElements)
		}
		this.state = {
			hasEntered: false,
			stickerDirection: this.e.sticker?.classList?.contains(
				'sticker--animation-direction-to-left'
			)
				? 'to-left'
				: 'to-right'
		}
		this.options = {
			bgImageLoadedClass: 'background--loaded'
		}
		this.mediaSmall = window.matchMedia('(max-width: 699px)')
		this.observer = null
		this.handleIntersect = this.handleIntersect.bind(this)

		this.init()

		return this
	}

	init() {
		if (!supportsWebAnimations()) {
			return
		}

		this.oberveImageLoadingState()
	}

	/**
	 * Only after all images have been loaded or errored start observing the content element for animation
	 */
	oberveImageLoadingState() {
		// const images = this.e.section.querySelectorAll("img");
		const backgroundImage = this.e.background?.querySelector('img')
		if (!backgroundImage) {
			this.observe()
			return
		}

		const imageLoaded = new Promise(resolve => {
			if (backgroundImage.complete) {
				resolve()
			} else {
				backgroundImage.addEventListener('load', () => {
					resolve()
				})
			}

			backgroundImage.onerror = resolve
		})

		// when promise resolves
		imageLoaded.then(() => {
			this.e.background.classList.add(this.options.bgImageLoadedClass)
			this.observe()
		})
	}

	/**
	 * Observe the content element for intersection and start entry or exit animation
	 */
	observe() {
		const config = {
			root: null,
			rootMargin: `${globalIntersectionMargin.top} 0% ${globalIntersectionMargin.bottom} 0%`,
			threshold: 0
		}
		this.observer = new IntersectionObserver(this.handleIntersect, config)

		this.observer.observe(this.e.section)
	}

	handleIntersect(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting && this.state.hasEntered === false) {
				this.handleEntry()
			} else if (!entry.isIntersecting && this.state.hasEntered === true) {
				this.handleExit()
			}
		})
	}

	handleEntry() {
		this.animateEntry()
		this.state.hasEntered = true
	}
	handleExit() {
		this.animateExit()
		this.state.hasEntered = false
	}

	animateEntry() {
		if (this.e.background) {
			maskImage(this.e.background, this.e.backgroundInner, 400, 1000)
		}
		if (this.e.sticker) {
			stickerSlide(
				this.e.stickerImage,
				this.mediaSmall.matches ? 'to-left' : this.state.stickerDirection,
				400,
				1000
			)
		}
		if (this.e.text) {
			this.e.textAnimationElements.forEach((element, index) => {
				textSlide(element, 400 + 66 * index, 1000)
			})
		}
	}

	animateExit() {
		if (this.e.background) {
			maskImageOut(this.e.background, this.e.backgroundInner, 0, 600)
		}
		if (this.e.sticker) {
			stickerSlideOut(this.e.stickerImage, 0, 600)
		}
		if (this.e.text) {
			this.e.textAnimationElements.forEach((element, index) => {
				textSlideOut(element, 0 + 33 * index, 600)
			})
		}
	}
}

function initSectionAnimations() {
	const elements = document.querySelectorAll('.js_grid-animate')
	elements.forEach(element => new SectionAnimation(element))
}
initSectionAnimations()

import { easings } from '../../../js/easings.js'
import { globalIntersectionMargin } from '../../../js/global'
export class Scrolldown {
	constructor(el) {
		this.s = {
			button: '.js_scrolldown__button',
			section: '.js_grid__section'
		}
		this.e = {
			element: el,
			button: el.querySelector(this.s.button),
			icon: el.querySelector(this.s.button + ' svg'),
			sections: document.querySelectorAll(this.s.section)
		}
		this.state = {
			activeSection: 0,
			activeSectionIndex: 0
		}
		this.options = {
			buttonHiddenClass: 'scrolldown__button--hidden'
		}

		this.init()

		return this
	}

	init() {
		if (!this.e.sections.length) {
			return
		}
		this.observeSections()
		this.e.button.addEventListener('click', this.handleClick.bind(this))
	}
	observeSections() {
		this.e.sections.forEach(section => {
			const observer = new IntersectionObserver(this.handleIntersect.bind(this), {
				root: null,
				rootMargin: `${globalIntersectionMargin.top} 0% ${globalIntersectionMargin.bottom} 0%`,
				threshold: 0
			})
			observer.observe(section)
		})
	}
	handleIntersect(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				this.state.activeSection = entry.target
				this.state.activeSectionIndex = Array.from(this.e.sections).indexOf(
					this.state.activeSection
				)
				this.handleActiveSectionChange()
			}
		})
	}
	handleActiveSectionChange() {
		if (this.state.activeSectionIndex === this.e.sections.length - 1) {
			this.e.button.classList.add(this.options.buttonHiddenClass)
		} else {
			this.e.button.classList.remove(this.options.buttonHiddenClass)
		}
	}
	handleClick() {
		const nextSectionIndex =
			this.state.activeSectionIndex >= this.e.sections.length - 1
				? this.e.sections.length - 1
				: this.state.activeSectionIndex + 1
		this.e.sections[nextSectionIndex].scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		})

		this.e.icon.animate(
			[
				{ transform: 'translateY(0)' },
				{ transform: 'translateY(3px)' },
				{ transform: 'translateY(0)' }
			],
			{
				duration: 700,
				iterations: 1,
				easing: easings.power3Out
			}
		)

		if (nextSectionIndex === this.e.sections.length - 1) {
			this.e.button.classList.add(this.options.buttonHiddenClass)
		}
	}
}

function initScrolldown() {
	const elements = document.querySelectorAll('.js_scrolldown')
	elements.forEach(element => new Scrolldown(element))
}
initScrolldown()

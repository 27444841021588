/**
 * Used for global functions and tests
 */

export const supportsWebAnimations = () => {
	return 'animate' in Element.prototype
}

if (!supportsWebAnimations()) {
	// class is used in css to show simple fallback transitions instead of web animations
	document.documentElement.classList.add('global-js--no-web-animations')
}

export const isTouchDevice = () => {
	return (
		'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
	)
}

/**
 * Used for observing when section is active
 */
export const globalIntersectionMargin = {
	top: '-40%',
	bottom: '-40%'
}

import { durations, easings } from './easings.js'
/***
 * This file exports functions starting animations for different elements
 * Needed functions are supposed to be imported in the component consuming the animation
 */

/**
 * Check animations of element and execute callback only after they finished
 * @param {*} element
 * @param {*} callback
 * @returns
 */
const executeOnAnimationsFinished = (elements, callback) => {
	const animations = elements.map(element => element.getAnimations()).flat()
	if (animations.length === 0) {
		callback()
		return
	}
	const animationsPromise = animations.map(animation => {
		return new Promise(resolve => {
			if (animation.playState === 'finished') {
				resolve()
				return
			}
			animation.onfinish = () => {
				resolve()
			}
		})
	})
	Promise.all(animationsPromise).then(() => {
		callback()
	})
}

export const maskImage = (element, elementChild, delay = 0, duration = durations.long1) => {
	executeOnAnimationsFinished([element, elementChild], () => {
		element.animate(
			[
				{
					transform: 'translateY(0)',
					clipPath: 'inset(min(40px, 5vw))',
					opacity: 1
				},
				{
					transform: 'translateY(0)',
					clipPath: 'inset(0)',
					opacity: 1
				}
			],
			{
				duration: duration / 2,
				delay: duration / 2 + delay,
				easing: easings.power3InOut,
				iterations: 1,
				fill: 'forwards'
			}
		)

		elementChild.animate(
			[
				{ clipPath: 'inset(0 100% 0 0)', opacity: 1 },
				{ clipPath: 'inset(0 0% 0 0)', opacity: 1 }
			],
			{
				duration: duration,
				delay: delay,
				easing: easings.power3InOut,
				iterations: 1,
				fill: 'forwards'
			}
		)
	})
}
export const maskImageOut = (element, elementChild, delay = 0, duration = durations.long1) => {
	executeOnAnimationsFinished([element, elementChild], () => {
		elementChild.animate([{ clipPath: 'inset(0 0% 0 0)' }, { clipPath: 'inset(0 0 100% 0)' }], {
			duration: duration,
			delay: delay + duration / 2,
			easing: easings.power3InOut,
			iterations: 1,
			fill: 'forwards'
		})
		element.animate([{ clipPath: 'inset(0px)' }, { clipPath: 'inset(min(40px, 5vw)' }], {
			duration: duration / 2,
			delay: delay,
			easing: easings.power3Out,

			iterations: 1,
			fill: 'forwards'
		})
	})
}
export const stickerSlide = (
	element,
	direction = 'to-right',
	delay = 0,
	duration = durations.long1
) => {
	let translate = '75vw, 0'
	switch (direction) {
		case 'to-right':
			translate = '-100vw, 0'
			break
		case 'to-left':
			translate = '100vw, 0'
			break
		default:
			break
	}

	executeOnAnimationsFinished([element], () => {
		element.animate(
			[
				{ transform: `translate(${translate})`, opacity: 1 },
				{ transform: 'translate(0)', opacity: 1 }
			],
			{
				duration: duration,
				delay: delay,
				easing: easings.power3InOut,
				iterations: 1,
				fill: 'forwards'
			}
		)
	})
}
export const stickerSlideOut = (element, delay = 0, duration = durations.long1) => {
	executeOnAnimationsFinished([element], () => {
		element.animate([{ transform: 'translate(0,-15vh)', opacity: 0 }], {
			duration: duration,
			delay: delay,
			easing: easings.power3Out,
			iterations: 1,
			fill: 'forwards'
		})
	})
}

export const textSlide = (element, delay = 0, duration = durations.long1) => {
	let translate = '0, 25vh'
	executeOnAnimationsFinished([element], () => {
		element.animate(
			[
				{ transform: `translate(${translate})`, opacity: 0 },
				{ transform: 'translate(0)', opacity: 1 }
			],
			{
				duration: duration,
				delay: delay,
				easing: easings.power3InOut,
				iterations: 1,
				fill: 'forwards'
			}
		)
	})
}
export const textSlideOut = (element, delay = 0, duration = durations.long1) => {
	let translate = '0, -25vh'
	executeOnAnimationsFinished([element], () => {
		element.animate([{ transform: `translate(${translate})`, opacity: 0 }], {
			duration: duration,
			delay: delay,
			easing: easings.power3Out,
			iterations: 1,
			fill: 'forwards'
		})
	})
}

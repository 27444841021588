import { globalIntersectionMargin } from '../../../js/global'
/**
 * Small dots that act as navigation for content sections and also indicate active section
 */
export class Dots {
	constructor(el) {
		this.s = {
			section: '.js_grid__section'
		}
		this.e = {
			element: el,
			sections: document.querySelectorAll(this.s.section),
			dots: []
		}
		this.state = {
			activeSection: 0,
			activeSectionIndex: 0
		}
		this.options = {
			dotActiveClass: 'dots__dot--active'
		}

		this.init()

		return this
	}

	init() {
		if (!this.e.sections.length) {
			return
		}
		this.builtDots()
		this.observeSections()
	}
	builtDots() {
		this.e.sections.forEach((section, index) => {
			const dot = document.createElement('button')
			dot.setAttribute('aria-label', `Zur Sektion ${index + 1} scrollen`)
			dot.classList.add('dots__dot')
			dot.addEventListener('click', () => {
				section.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})
			})
			this.e.dots.push(this.e.element.appendChild(dot))
		})
	}
	observeSections() {
		this.e.sections.forEach(section => {
			const observer = new IntersectionObserver(this.handleIntersect.bind(this), {
				root: null,
				rootMargin: `${globalIntersectionMargin.top} 0% ${globalIntersectionMargin.bottom} 0%`,
				threshold: 0
			})
			observer.observe(section)
		})
	}
	handleIntersect(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				this.state.activeSection = entry.target
				this.state.activeSectionIndex = Array.from(this.e.sections).indexOf(
					this.state.activeSection
				)
				this.handleActiveSectionChange()
			}
		})
	}
	handleActiveSectionChange() {
		this.e.dots.forEach((dot, index) => {
			if (index === this.state.activeSectionIndex) {
				dot.classList.add(this.options.dotActiveClass)
			} else {
				dot.classList.remove(this.options.dotActiveClass)
			}
		})
	}
}

function initDots() {
	const elements = document.querySelectorAll('.dots')
	elements.forEach(element => new Dots(element))
}
initDots()

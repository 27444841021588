export const easings = {
	power2InOut: 'cubic-bezier(0.66, 0, 0.34, 1)',
	power2In: 'cubic-bezier(0.4, 0, 0.68, 0.06)',
	power2Out: 'cubic-bezier(0.32, 0.94, 0.6, 1)',
	power3InOut: 'cubic-bezier(0.76, 0, 0.24, 1)',
	power3In: 'cubic-bezier(0.52, 0, 0.74, 0)',
	power3Out: 'cubic-bezier(0.26, 1, 0.48, 1)'
}

export const durations = {
	short1: 50,
	short2: 100,
	short3: 150,
	short4: 200,
	medium1: 250,
	medium2: 300,
	medium3: 350,
	medium4: 400,
	long1: 500,
	long2: 600,
	long3: 700,
	long4: 800,
	extraLong1: 1000,
	extraLong2: 1250,
	extraLong3: 1500,
	extraLong4: 2000
}
